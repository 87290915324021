<template>
  <div class="library">
    <div class="library__header">
      <h1 class="library__header_title">Library</h1>

      <div
        class="library__header__search"
        :class="{ 'library__header__search--search-active': search }"
      >
        <div class="library__header__search_input-container">
          <BaseInput
            class="library__header__search_input"
            :element="search"
            placeholder="Search Library"
            @input="inputSearch"
          />
          <IconSearch class="library__header__search-icon" color="#fff" />
        </div>
      </div>
    </div>

    <div class="library__body">
      <section v-for="(section, index) in library" :key="index" class="library__body__section">
        <div class="library__body__section_title-container">
          <span class="title">{{ section.title }}</span>
          <BaseButton
            v-show="section.title !== 'Books'"
            class="button button--lg button--white button--oval"
            text="View All"
            @click.native="goToPage(section.path)"
          />
        </div>

        <div class="library__body__section_links-container">
          <div v-for="(link, index) in section.links.slice(-3)" :key="`link-${index}`" class="item">
            <router-link :to="{ path: `${section.path}${link.slug}` }" class="link">
              <span class="title">{{ link.title }}</span>
            </router-link>
          </div>
        </div>

        <BaseButton
          v-show="section.title !== 'Books'"
          class="button button--lg button--white button--oval mobile"
          text="View All"
          @click.native="goToPage(section.path)"
        />
      </section>

      <section class="library__body__section">
        <div class="library__body__section_title-container">
          <span class="title">Video</span>
        </div>

        <div class="library__body__section_video-container">
          <Video />
        </div>
      </section>
    </div>

    <BaseButton
      class="library__navigation button--outline-white button--lg button--oval"
      text="Back to Top"
      @click="backToTop"
    />
  </div>
</template>

<script>
import { scroller } from "vue-scrollto/src/scrollTo";
import { mapGetters, mapActions, mapMutations } from "vuex";
import BaseInput from "@/components/inputs/BaseInput.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import Video from "@/components/Video.vue";

export default {
  components: { BaseInput, IconSearch, BaseButton, Video },

  data() {
    return {
      search: "",
    };
  },
  head: {
    title: function () {
      return {
        inner: "GH - Library",
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },
  computed: {
    ...mapGetters("publicLibrary", {
      library: "getLibrary",
    }),
  },

  async created() {
    await this.apiGetLibrary(this.search);

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Library",
        to: "/library",
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  watch: {
    "$route.query.search": function () {
      this.search = this.$route.query.search ?? "";
    },
  },

  methods: {
    ...mapActions("publicLibrary", ["apiGetLibrary"]),
    ...mapMutations(["setBreadcrumbs"]),

    goToPage(path) {
      this.$router.push({ path: path.slice(0, -1) });
    },

    backToTop() {
      const firstScrollTo = scroller();
      firstScrollTo(".header_breadcrumbs");
    },

    inputSearch(val) {
      this.search = val;
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        await this.apiGetLibrary(this.search);
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.library {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $lightBlack;
  min-height: 100vh;
  min-width: 100vw;
  font-size: 2.1rem;
  line-height: 3.5rem;
  @media (max-width: $xs) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  &__header {
    margin-top: 15.6rem;
    @media (max-width: $xs) {
      margin-top: 28.3rem;
    }

    &_title {
      width: 100vw;
      display: inline-block;
      text-align: center;
      font-family: $fontBodoni;
      font-size: 6.2rem;
      line-height: 6.8rem;
      @media (max-width: $xs) {
        font-size: 4rem;
        line-height: 6rem;
      }
    }

    &__search {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100vw;
      margin-bottom: 85px;
      @media (max-width: $xs) {
        padding: 0rem 2.8rem;
      }
      &--search-active {
        padding-top: 0;
        @media (max-width: $xxs) {
          padding-top: 2.35rem;
        }
      }

      &_input {
        width: 100%;
        max-width: none;
        margin: 0;
        flex-grow: 1;
        &::v-deep {
          .form-control__input {
            height: 4.4rem;
            padding: 0 10px;
            font-family: $fontOpenSans;
            font-weight: 700;
            font-size: 2.2rem;
            line-height: 1.23;
            color: $white;
            border: none;
          }
          .form-control__label {
            font-family: $fontOpenSans;
            font-size: 2.2rem;
            font-weight: 400;
            color: $white;
            opacity: 0.7;
            background: none;
            padding: 0;
            left: 1rem;
          }
          .form-control__input:focus ~ .form-control__label,
          .form-control__input:not(:placeholder-shown) ~ .form-control__label {
            display: none;
          }
        }
        &-container {
          display: flex;
          padding: 2rem 1.5rem 1rem 0.5rem;
          margin-top: 6rem;
          border-bottom: 1px solid $white;
          width: 100%;
          max-width: 65.4rem;
          @media (max-width: $xs) {
            padding: 2rem 0rem 1rem 0rem;
          }
        }
      }
      &-icon {
        width: auto;
        height: auto;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;

    :last-child {
      border-bottom: none;
    }

    &__section {
      display: flex;
      border-bottom: 1px solid $white;
      padding: 8rem 16.5rem;
      @media (max-width: $md) {
        flex-direction: column;
        padding: 8rem 11.5rem;
      }
      @media (max-width: $xs) {
        padding: 3.8rem 2.8rem;
      }

      &_title-container {
        padding-top: 3rem;
        width: 35%;
        @media (max-width: $md) {
          width: 100%;
          margin-bottom: 4.5rem;
          padding-top: 0rem;
        }
        @media (max-width: $xs) {
          margin-bottom: 2rem;
        }

        .title {
          display: inline-block;
          text-align: center;
          font-family: $fontBodoni;
          font-size: 6.2rem;
          line-height: 6.8rem;
          @media (max-width: $xs) {
            font-size: 4rem;
            line-height: 6rem;
          }
        }

        .button {
          width: 181px;
          margin-top: 4rem;
          @media (max-width: $md) {
            display: none;
          }
        }
      }

      &_links-container {
        width: 65%;
        @media (max-width: $md) {
          width: 100%;
        }

        .item {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid $white;
          padding: 3rem 0rem;
          cursor: pointer;
          @media (max-width: $xs) {
            padding: 2rem 0rem;
          }
          .link {
            display: block;
            text-decoration: none;
          }
          .title {
            font-size: 2.7rem;
            line-height: 3.6rem;
            font-family: $fontBodoni;
            @media (max-width: $xs) {
              font-size: 2.5rem;
              line-height: 3.1rem;
            }
          }
        }

        :last-child {
          border-bottom: none;
        }
      }
      &_video-container {
        width: 65%;
        display: grid;
        grid-column-gap: 2rem;
        grid-row-gap: 5rem;
        grid-template-columns: 1fr 1fr;
        @media (max-width: $md) {
          width: 100%;
        }
        @media screen and (max-width: $xs) {
          grid-template-columns: 1fr;
          grid-column-gap: 25px;
          grid-row-gap: 25px;
        }
      }

      .button.mobile {
        width: 192px;
        margin-top: 4.5rem;
        display: none;
        @media (max-width: $md) {
          display: flex;
        }
        @media (max-width: $xs) {
          margin-top: 2rem;
          height: 55px;
        }
      }
    }
  }

  &__navigation {
    display: none;
    @media (max-width: $xs) {
      display: flex;
      margin: 3.2rem 0rem 5rem;
      width: 192px;
      height: 55px;
    }
  }
}
</style>
